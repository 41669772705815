import * as React from "react";
import { Head } from "../components/Header/head";
import { Navbar } from "../components/Navbar/navbar";
import VideoIndex from "../assets/videos/video_index.mp4";
import VideoUNO from "../assets/videos/video_corporativo.mp4";
import VideoDOS from "../assets/videos/video_corporativo_small.mp4";
import play from "../assets/images/ICONOS/play.png";
import portada from "../assets/images/portada.jpg";
import portadaMovil from "../assets/images/portada_movil.jpg";

import portadaComuniones from "../assets/images/comuniones_portada.jpg";
import eventoImg from '../assets/images/evento.jpeg'
import comunionespdf from "../assets/especial_comuniones.pdf";
import menuPeru from '../assets/images/menu_jornadas_peru.jpeg'
import "../styles/index.css";
import Footer from "../components/Footer/footer";
import { SSRProvider } from "@react-aria/ssr";
import { Link } from "gatsby";

const IndexPage = () => {
  const [showVideo, setShowVideo] = React.useState(false);
  const [showVideoMobile, setShowVideoMobile] = React.useState(false);
  return (
    <SSRProvider>
      <Head title="Posada Los Jerónimos - Principal" />
      <Navbar transparent />
      <div className="home-video-wrapper">
        <span className="welcome-message">Bienvenido a Los Jerónimos</span>
        {/*  <video className="home-video" autoPlay loop muted preload="auto">
          <source src={VideoIndex} type="video/mp4" />
        </video> */}
        <img className="portada-browser" src={portada} />
        <img className="portada-movil" src={portadaMovil} />
      </div>
      <section className="welcome-cards-wrapper">
        <div className="welcome-cards">
          <Link to={`/servicios/panaderia`}>
            <div className="home-card left">
              <div className="filter"></div>
              <div className="inner-card">
                <h2 className="title-card">Panadería</h2>
              </div>
            </div>
          </Link>
          <Link to={`/servicios/alojamiento`}>
            <div className="home-card home-card-big">
              <div className="filter"></div>
              <div className="inner-card">
                <h2 className="title-card">Alojamiento</h2>
              </div>
            </div>
          </Link>
          <Link to={`/servicios/restaurante`}>
            <div className="home-card rigth">
              <div className="filter"></div>
              <div className="inner-card">
                <h2 className="title-card">Restaurante</h2>
              </div>
            </div>
          </Link>
        </div>
      </section>
      <section className="home-videos">
        <div className="video">
          {showVideo && (
            <>
              {!showVideoMobile ? (
                <video
                  className="video-desktop"
                  src={VideoUNO}
                  controls
                  autoPlay
                >
                  <source src={VideoIndex} type="video/mp4" />
                </video>
              ) : (
                <video className="video-small" src={VideoDOS} controls autoPlay>
                  <source src={VideoIndex} type="video/mp4" />
                </video>
              )}
            </>
          )}
          {!showVideo && (
            <>
              <div className="wrapper-boton-video">
                <div
                  className="boton-video"
                  onClick={() => {
                    setShowVideo(!showVideo);
                    setShowVideoMobile(false);
                  }}
                >
                  <img className="play-icon" src={play} />{" "}
                  <span> Conocenos</span>
                </div>
              </div>
              <div className="wrapper-boton-video-small">
                <div
                  className="boton-video"
                  onClick={() => {
                    setShowVideo(!showVideo);
                    setShowVideoMobile(true);
                  }}
                >
                  <img className="play-icon" src={play} />{" "}
                  <span> Conocenos</span>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      {/*      <section id="comuniones" className="comuniones">
        <h2>Especial comuniones</h2>
        <a className="comuniones-img" download href={comunionespdf}>
          <img src={portadaComuniones} />
        </a>
      </section> */}
{/*       <section id="comuniones" className="comuniones">
        <h2>XI Jornadas de cocina peruana</h2>
        <a className="comuniones-img" download href={menuPeru}>
          <img src={menuPeru} />
        </a>
      </section> */}
      <section className="reviews">
        <div className="reviews-wrapper">
          <h2>Opiniones sobre nuestra posada rural</h2>
          <div class="elfsight-app-d9e7cabf-b503-4991-9687-10b955776c01"></div>
        </div>
      </section>
      <Footer slim />
    </SSRProvider>
  );
};

export default IndexPage;
